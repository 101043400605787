<script>
import MissonAndVisionVue from '@/components/introduce/Service/MissonAndVision.vue'
import BackgroundAndPurposeVue from '@/components/introduce/Service/BackgroundAndPurpose.vue'
import KeyFeaturesVue from '@/components/introduce/Service/KeyFeatures.vue'
import InformationProvidedVue from '@/components/introduce/Service/InformationProvided.vue'
export default {
  components: { MissonAndVisionVue, BackgroundAndPurposeVue, KeyFeaturesVue, InformationProvidedVue }
}
</script>

<template>
  <div class="wf__service">
    <MissonAndVisionVue></MissonAndVisionVue>
    <BackgroundAndPurposeVue></BackgroundAndPurposeVue>
    <KeyFeaturesVue></KeyFeaturesVue>
    <InformationProvidedVue></InformationProvidedVue>
  </div>
</template>

<style scoped>
.wf__service {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
