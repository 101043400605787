<script>
export default {
  components: {},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<template>
  <div class="wf__bap">
    <div class="wf__bap-container">
      <div class="wf__bap-title-wrapper">
        <div class="wf__intro-title-badge uppercase">BACKGROUND</div>
        <div class="wf__intro-title">
          추진 배경 및 목적
          <span class="wf__intro-subtitle">Background and purpose of establishment</span>
        </div>
      </div>

      <div class="wf__intro-desc-item wf__bap-background">
        <div class="wf__intro-desc-title">추진배경</div>
        <div class="wf__intro-desc-content">
          단발성 유망기술 발굴과 연계해서 나아가 유망연구실 발굴 및 디지털 연계를 통한 차별화와 선도형 기업협력 플랫폼을 고도화하기 위해
          설립되었습니다.
        </div>
      </div>

      <div class="wf__intro-desc-item wf__bap-purpose">
        <div class="wf__intro-desc-title">추진목적</div>
        <div class="wf__bap-purpose-wrapper">
          <div class="wf__bap-purpose-item">
            <div class="wf__bap-purpose-item-idx">01</div>
            <div class="wf__bap-purpose-item-desc">대학 및 연구소 등의 유망연구실 (연구자) 발굴</div>
          </div>

          <div class="wf__bap-purpose-item">
            <div class="wf__bap-purpose-item-idx">02</div>
            <div class="wf__bap-purpose-item-desc">기업과 연구실의 매칭 네트워크 운영</div>
          </div>

          <div class="wf__bap-purpose-item">
            <div class="wf__bap-purpose-item-idx">03</div>
            <div class="wf__bap-purpose-item-desc">디지털 연계플랫폼 구축</div>
          </div>

          <div class="wf__bap-purpose-item">
            <div class="wf__bap-purpose-item-idx">04</div>
            <div class="wf__bap-purpose-item-desc">공동 사업화 기획지원</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__bap {
  display: flex;
  justify-content: center;
  width: 100%;

  background-color: var(--wf-primary-color);
  color: #fff;

  padding: 60px 1em;
}
.wf__bap-container {
  display: grid;
  grid-template-columns: 1.6fr 1fr;
  column-gap: 120px;
  width: 100%;
  max-width: var(--wf-container-width);
}

.wf__bap-purpose {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}

.wf__bap-background {
  margin-top: 40px;
}

.wf__bap-purpose-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
}

.wf__bap-purpose-item-idx {
  font-size: 30px;
  border-bottom: 2px solid #fff;
  width: max-content;
  font-weight: 500;
}
.wf__bap-purpose-item-desc {
  font-size: 20px;
  font-weight: 600;
}

.wf__intro-title-badge,
.wf__intro-title,
.wf__intro-subtitle {
  color: #fff !important;
}
.wf__intro-desc-title::before {
  background-color: #fff !important;
}

.wf__intro-desc-content {
  color: #fff !important;
}

@media screen and (max-width: 992px) {
  .wf__bap-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .wf__bap-title-wrapper,
  .wf__bap-background,
  .wf__bap-purpose {
    grid-column: 1 / 3;
  }
  .wf__bap-purpose {
    margin-top: 40px;
    grid-row: 3 / 4;
  }
}
</style>
