<script>
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'
import Background from '@/assets/images/004.jpg'

export default {
  components: { ImageBackgroundVue },
  data() {
    return {
      imgSrc: Background
    }
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<template>
  <div class="wf__info">
    <ImageBackgroundVue :src="imgSrc" height="410px">
      <div class="wf__info-overlay">
        <div class="wf__info-content">
          <div class="wf__info-title-wrapper">
            <div class="wf__intro-title-badge">Information provided</div>
            <div class="wf__intro-title">
              제공되는 정보
              <span class="wf__intro-subtitle">Space connecting the lab to the enterprise</span>
            </div>
          </div>

          <div class="wf__info-content-wrapper">
            <div class="wf__intro-desc-item wf__info-content-item">
              <div class="wf__intro-desc-title">대학 및 연구기관 연구실</div>
              <div class="wf__intro-desc-content">유망연구실 플랫폼에 동의한 산학연 추천을 통해 엄선한 대학 내 연구실 정보제공</div>
            </div>

            <div class="wf__intro-desc-item wf__info-content-item">
              <div class="wf__intro-desc-title">기관 연구자</div>
              <div class="wf__intro-desc-content">유망연구실 플랫폼에 동의한 산학연 추천을 통해 엄선한 연구기관 연구자 정보 제공</div>
            </div>
          </div>
        </div>
      </div>
    </ImageBackgroundVue>

    <div class="wf__info-cards">
      <div class="wf__info-card-item">
        <div class="wf__info-card-item-title">논문</div>
        <div class="wf__info-card-item-desc">연구실에서 보유하고 있거나 연구중인 논문을 NTIS에 기반하여 보여드립니다.</div>
      </div>
      <div class="wf__info-card-item">
        <div class="wf__info-card-item-title">지재권</div>
        <div class="wf__info-card-item-desc">연구실에서 보유중인 지재권 현황을 NTIS에 기반하여 보여드립니다.</div>
      </div>
      <div class="wf__info-card-item">
        <div class="wf__info-card-item-title">과제정보</div>
        <div class="wf__info-card-item-desc">연구실에서 수행 중이거나 완료된 프로젝트를 NTIS에 기반하여 보여드립니다.</div>
      </div>
      <div class="wf__info-card-item">
        <div class="wf__info-card-item-title">연구실 정보</div>
        <div class="wf__info-card-item-desc">연구실에서 직접 입력한 연구실 연구역량 및 동향정보에 대하여 제공됩니다.</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wf__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.wf__info-overlay {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
}

.wf__info-content {
  width: 100%;
  max-width: var(--wf-container-width);

  padding: 0 1em;
  margin-top: 100px;
}
.wf__info-title-wrapper {
  padding-bottom: 18px;
  border-bottom: 1px solid #fff;
  margin-bottom: 18px;
}

.wf__info-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 118px;
}
.wf__info-content-item {
  flex: 1;
}

.wf__info-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 18px;
  width: 100%;
  max-width: var(--wf-container-width);

  padding: 0 1em;
  translate: 0 -40px;
  z-index: 13;
  color: var(--wf-black-color);
}
.wf__info-card-item {
  padding: 30px;
  background-color: #fff;
  border: 1px solid var(--wf-gray-400);
}
.wf__info-card-item-title {
  font-size: 24px;
  font-weight: 500;
  width: max-content;
  border-bottom: 2px solid var(--wf-black-color);

  margin-bottom: 8px;
}
.wf__info-card-item-desc {
  font-size: 20px;
  font-weight: 300;
}

.wf__intro-title-badge,
.wf__intro-title,
.wf__intro-subtitle {
  color: #fff !important;
}
.wf__intro-desc-title::before {
  background-color: #fff !important;
}

:deep(.outer) {
  height: 30em;
}

.wf__intro-desc-content {
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  :deep(.outer) {
    height: 40em;
  }
  .wf__info-content-wrapper {
    gap: 56px;
  }
  .wf__info-content-item {
    min-width: 100%;
  }
  .wf__info-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
