<script>
export default {
  components: {},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<template>
  <div class="wf__mav">
    <div class="wf__mav-title-wrapper">
      <div class="wf__intro-title-badge uppercase">mission</div>
      <div class="wf__intro-title">
        미션&비전
        <span class="wf__intro-subtitle">Space connecting the lab to the enterprise</span>
      </div>
    </div>

    <div class="wf__mav-typo">
      <div class="wf__mav-typo-line-first">연구실과 기업을 이어주는</div>
      <div class="wf__mav-typo-line-second">든든한 디딤돌</div>
      <div class="wf__mav-typo-line-third">
        <span class="wf__mav-typo-line-third--underline">유망연구실 통합플랫폼</span>으로 시작합니다
      </div>
    </div>

    <div class="wf__mav-desc">
      <div class="wf__intro-desc-item">
        <div class="wf__intro-desc-title">유망연구실 미션</div>
        <div class="wf__intro-desc-content">유망연구실과 기업의 연계가 가능한 정보서비스를 제공합니다</div>
      </div>

      <div class="wf__intro-desc-item">
        <div class="wf__intro-desc-title">유망연구실 비전</div>
        <div class="wf__intro-desc-content">
          유망기술을 공급하는 유망연구실의 발굴 및 육성하여 기업과 연계하여
          <br />
          기업협력 선단계에서 공동 연구기획을 도모하여 선도화된 기업협력 시스템을 구축 합니다.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__mav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;

  min-height: 540px;
  width: 100%;
  max-width: var(--wf-container-width);

  padding: 100px 1em;
}

.wf__mav-title-wrapper {
  grid-column: 1 / 3;
}
.wf__mav-typo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 14em;
  font-size: var(--wf-text-48);
  color: var(--wf-primary-color);
  font-weight: 500;
  line-height: 1.4;
}

.wf__mav-typo-line-second {
  color: #fff;
  background-color: var(--wf-primary-color);
  max-width: max-content;
}
.wf__mav-typo-line-third {
  font-weight: 500;
}
.wf__mav-typo-line-third--underline {
  display: inline-block;
  font-weight: 700;
  border-bottom: 2px solid var(--wf-primary-color);
  line-height: 1.25;
}
.wf__mav-desc {
  display: flex;
  flex-direction: column;
  gap: 34px;
}

@media screen and (max-width: 992px) {
  .wf__mav {
    padding-left: 2em;
    padding-right: 2em;
    row-gap: 40px;
  }
  .wf__mav-typo,
  .wf__mav-desc {
    grid-column: 1 / 3;
  }
}
</style>
