<script>
import KeyFeature from '@/assets/images/key_feature.png'
import KeyFeatureArrowDown from '@/assets/images/key_feature_arrow_down.png'
export default {
  components: {},
  data() {
    return {
      KeyFeature,
      KeyFeatureArrowDown
    }
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<template>
  <div class="wf__features">
    <div class="wf__features-pic">
      <div class="wf__features-title-wrapper">
        <div class="wf__intro-title-badge uppercase">Key Features</div>
        <div class="wf__intro-title">
          주요기능
          <span class="wf__intro-subtitle">Space connecting the lab to the enterprise</span>
        </div>
      </div>

      <div class="wf__features-pic-desc">
        <img :src="KeyFeature" alt="" srcset="" />
        <!-- <div class="wf__features-pic-target">연구실</div>
        <div class="wf__features-pic-direction">
          연구실 및 정보등록
          <div class="wf__features-pic-arrow"></div>
          <div class="wf__features-pic-arrow fliped"></div>
          발굴 및 육성
        </div>
        <div class="wf__features-pic-circle-wrapper">
          <div class="wf__features-pic-circle first">
            <div class="wf__features-pic-circle second">
              <div class="wf__features-pic-circle third">유망연구실</div>
            </div>
          </div>

          <div class="wf__features-pic-arrow-down"></div>
        </div>
        <div class="wf__features-pic-direction">
          연구 및 정보제공
          <div class="wf__features-pic-arrow"></div>
          <div class="wf__features-pic-arrow fliped"></div>
          연구실협력신청/사업기획
        </div>
        <div class="wf__features-pic-target">기관/기업</div> -->
      </div>

      <div class="wf__features-pic-tail">
        <div class="wf__features-pic-tail-text">유망연구실과 기업의 연계정보서비스 제공</div>
      </div>
    </div>

    <div class="wf__features-desc">
      <div class="wf__intro-desc-item">
        <div class="wf__intro-desc-title">다양성</div>
        <div class="wf__intro-desc-content">다양한 분야의 기술을 보유한 유망연구실로 한정되어 있지 않은 정보제공</div>
      </div>

      <div class="wf__intro-desc-item">
        <div class="wf__intro-desc-title">활용성</div>
        <div class="wf__intro-desc-content">간편하고 용이하게 이용하여 기업협력 및 연구기획에 활용</div>
      </div>

      <div class="wf__intro-desc-item">
        <div class="wf__intro-desc-title">전문성</div>
        <div class="wf__intro-desc-content">부가가치가 높으며 미래를 선도할 수 있는 기술을 연구실에서 직접제공</div>
      </div>

      <div class="wf__intro-desc-item">
        <div class="wf__intro-desc-title">접근성</div>
        <div class="wf__intro-desc-content">접근이 용이하고 검색이 간편하여 찾고자 하는 정보를 한번에 얻을 수 있는 플랫폼</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wf__features {
  display: flex;
  flex-wrap: wrap;

  gap: 7em;

  width: 100%;
  max-width: var(--wf-container-width);
  padding: 100px 1em;
}

.wf__features .wf__intro-desc-content {
  font-size: 18px !important;
  /* white-space: nowrap; */
}
.wf__features-pic {
  flex: 1.5;
  max-width: 710px;
}
.wf__features-pic-desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  margin-top: 4.8em;

  font-size: 10px;
}
.wf__features-pic-desc img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.wf__features-pic-target {
  font-size: 3em;
  font-weight: 500;
  color: #5f5f5f;
}
.wf__features-pic-direction {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8em;
  font-size: 1.6em;
  font-weight: 400;
  color: #5f5f5f;
  text-align: center;
}
.wf__features-pic-arrow {
  position: relative;
  margin-bottom: 4em;
  height: 2px;
  background-color: var(--wf-gray-400);
  width: 7em;
}
.wf__features-pic-arrow::before {
  content: '';
  display: block;
  position: absolute;
  top: -100%;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--wf-gray-400);
}
.wf__features-pic-arrow::after {
  content: '';
  display: block;
  position: absolute;
  top: calc(-100% - 1px);
  right: -9px;

  width: 0;
  height: 0;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 7px solid var(--wf-gray-400);
  border-right: 7px solid transparent;
}
.wf__features-pic-arrow.fliped {
  rotate: 180deg;
}
.wf__features-pic-arrow:last-child {
  margin-bottom: 0;
}

.wf__features-pic-target {
  margin-bottom: 3em;
  min-width: 110px;
  text-align: center;
}
.wf__features-pic-direction {
  margin-bottom: 5em;
}
.wf__features-pic-circle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 3em;
}
.wf__features-pic-circle {
  border-radius: 50%;

  font-size: 1.4em;
  font-weight: 500;
}
.wf__features-pic-circle.first {
  width: 15em;
  height: 15em;
  min-width: 15em;
  min-height: 15em;
  padding: 1em;

  border: 1px solid var(--wf-gray-400);
}
.wf__features-pic-circle.second {
  width: 100%;
  height: 100%;
  padding: 7px;

  border: 2px solid var(--wf-primary-color);
}
.wf__features-pic-circle.third {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--wf-primary-color);
  color: #fff;

  width: 100%;
  height: 100%;
}
.wf__features-pic-tail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.wf__features-pic-arrow-down {
  width: 0;
  height: 0;
  border-bottom: 26px solid transparent;
  border-top: 26px solid var(--wf-secondary-color);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
}

.wf__features-pic-tail-text {
  width: 100%;

  padding: 8px 0;
  margin-top: 1.15em;

  background-color: var(--wf-primary-color);
  border-radius: 22px;

  color: #fff;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}
.wf__features-desc {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3em;
}

@media screen and (max-width: 992px) {
  .wf__features {
    flex-direction: column;
    gap: 40px;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-wrap: nowrap;
  }

  .wf__features .wf__intro-desc-content {
    white-space: pre-wrap;
  }

  .wf__features-pic-desc {
    justify-content: center;
    font-size: 10px;
  }
  .wf__features-pic-tail {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .wf__features-pic-desc {
    font-size: 8px;
  }
  .wf__features-pic-tail-text {
    font-size: 24px;
  }

  .wf__features-pic-target {
    min-width: 0px;
  }
}
@media screen and (max-width: 425px) {
  .wf__features-pic-desc {
    font-size: 5px;
  }
  .wf__features-pic-tail-text {
    font-size: 16px;
  }
}
</style>
